@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,700;0,900;1,700&display=swap');
@import url('https://use.typekit.net/erj4cua.css');

html {
  scroll-behavior: smooth;
  scroll-padding-top: 15rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background: linear-gradient(180deg, #fee000, #6cbe45);
  background-size: 200% 200%;
  animation: gradient 8s ease infinite;
  min-height: 24rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
}

.building-bg {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: url(./assets/images/envative-building-bg5.png);
  filter: grayscale(1);
  background-position: left center;
  background-size: cover;
  animation: gradient 64s infinite;
}

.MuiPaper-buildingBG {
  background: url(./assets/images/envative-building-bg5.png);
}

.header-banner,
.section-banner,
.footer-banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 200% 200%;
  animation: gradient 8s ease infinite;
}

.section-banner {
  padding: 16rem 1rem;
  margin-top: 0;
}

.App-link {
  color: #61dafb;
}

.gradient-1 {
  background: linear-gradient(180deg, #fee000, #6cbe45);
  background-size: 200% 200%;
  animation: gradient 8s ease infinite;
}
.gradient-2 {
  background: linear-gradient(180deg, #6cbe45, #02a0c6);
  background-size: 200% 200%;
  animation: gradient 8s ease infinite;
}
.gradient-3 {
  background: linear-gradient(180deg, #02a0c6, #ee2a25);
  background-size: 200% 200%;
  animation: gradient 8s ease infinite;
}
.gradient-4 {
  background: linear-gradient(180deg, #ee2a25, #fee000);
  background-size: 200% 200%;
  animation: gradient 8s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 50% 0%;
  }

  50% {
    background-position: 50% 100%;
  }

  100% {
    background-position: 50% 0%;
  }
}

.section-banner:after,
.App-header:after {
  content: '';
  background-image: linear-gradient(to left top, white 0%, white 50%, transparent 50.5%);
  position: absolute;
  width: 100%;
  height: 12rem;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.section-banner:before,
.footer-banner:before {
  content: '';
  background-image: linear-gradient(to right bottom, white 0%, white 50%, transparent 50.5%);
  position: absolute;
  width: 100%;
  height: 12rem;
  top: 0;
  left: 0;
  z-index: 1;
}

.mainMenu {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: -100vh;
  backdrop-filter: blur(8px);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255 255 255 / 90%);
}

.mainMenu.show {
  top: 0 !important;
  background-color: rgb(0 0 0 / 80%);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Swiper */
.swiper-slide {
  height: auto !important;
}

.swiper-slide .MuiCard-root {
  height: 100%;
}

#background-video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

/* Forms */
.marketingForm > div,
.marketingForm > div > div {
  max-width: 100% !important;
}

.marketingForm .textFormFieldBlock input,
.marketingForm textarea {
  padding: 0.5rem 1rem !important;
  border-radius: 0.5rem !important;
  border: none !important;
  background-color: #fff;
}

.marketingForm textarea {
  width: 100% !important;
  max-width: 100% !important;
  background-color: #e3e3e3 !important;
}

.marketingForm table,
.marketingForm tbody,
.marketingForm th,
.marketingForm tr,
.marketingForm .columnContainer.inner {
  max-width: 100% !important;
  width: 100% !important;
}

.marketingForm table.outer {
  display: inline-table !important;
}

.marketingForm .columnContainer {
  max-width: 50% !important;
  width: 50% !important;
}

.marketingForm .multiOptionSetFormFieldBlock,
.marketingForm .textFormFieldBlock,
.marketingForm div[data-editorblocktype='Captcha'] {
  padding: 20px 10px !important;
}

.marketingForm .multiOptionSetFormFieldBlock fieldset div {
  width: 100%;
}

.marketingForm .multiOptionSetFormFieldBlock fieldset input {
  margin-right: 0.5rem;
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 38px;
  opacity: 0;
}

.marketingForm .multiOptionSetFormFieldBlock fieldset label {
  list-style: none;
  background-color: #d0d0d0;
  padding: 0.25rem 1rem;
  border-radius: 1rem;
  margin: 0 0.5rem 0.5rem 0;
  width: 100%;
  text-align: center;
}

.marketingForm .multiOptionSetFormFieldBlock fieldset input[type='checkbox']:checked + label,
.radiobuttonlist input[type='radio']:checked + label {
  background-color: #0d7ec3;
  color: #fff;
}
.marketingForm div[data-editorblocktype='Captcha'] input {
  border-radius: 0.5rem !important;
  border: none !important;
  background-color: #fff;
}

div[data-required].textFormFieldBlock label::after,
div[data-required].dateTimeFormFieldBlock label::after,
div[data-required].lookupFormFieldBlock label::after,
div[data-required] div.twooption_checkbox label::after,
div[data-required] label.block-label::after,
div[data-required='true'].consentBlock label::after,
div[data-editorblocktype='Captcha'] label[id^='wlspispHipInstructionContainer']::after {
  position: absolute;
}

@media all and (min-width: 0) and (max-width: 1400px) {
  .marketingForm .columnContainer {
    max-width: 100% !important;
    width: 100% !important;
    display: inline;
  }
}
